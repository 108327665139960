import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const Construct = () => (
  <Layout>
    <SEO title="納入実績" />
    <div>
      <section className="w-full lg:mr-0 lg:ml-auto lg:w-2/3">
        <div className="pt-32">
          <h1 className="font-mincho text-3xl text-primary">成田空港</h1>
        </div>
        <div className="mt-8 grid w-full grid-cols-1 gap-2 md:grid-cols-2 md:gap-4 ">
          <StaticImage
            src="../../images/works/narita/1.jpg"
            alt="成田空港1"
            className="w-full"
            quality={100}
          />
          <StaticImage
            src="../../images/works/narita/2.jpg"
            alt="成田空港2"
            className="w-full"
            quality={100}
          />
          <StaticImage
            src="../../images/works/narita/3.jpg"
            alt="成田空港3"
            className="w-full"
            quality={100}
          />
          <StaticImage
            src="../../images/works/narita/4.jpg"
            alt="成田空港4"
            className="w-full"
            quality={100}
          />
          <StaticImage
            src="../../images/works/narita/5.jpg"
            alt="成田空港5"
            className="w-full"
            quality={100}
          />
        </div>
      </section>
    </div>
  </Layout>
)

export default Construct
